<template>
  <div class="w-100">
    <InviteYourFriend />
  </div>
</template>

<script>
import InviteYourFriend from '@/components/profile/invite-your-friend/Index.vue'
export default {
  name: 'InviteYourFriendPage',
  components: { InviteYourFriend }
}
</script>
