<template>
  <div
    class="invite-your-friend p-12 radius-14 d-flex flex-column justify-content-center align-items-center w-100 text-center white-color-bg"
  >
    <img
      class="invite-img"
      src="@/assets/images/profile/invite-your-friend.svg"
    >
    <h3 class="fs-24 bold-font m-t-24">
      ادعوا صديقك
    </h3>
    <p class="fs-14 light-gray-color-text m-t-14 m-b-22 copy-message">
      هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ
      عن التركيز.
    </p>
    <p class="fs-16 bold-font dark-gray-color-text">
      شارك الكود
    </p>

    <div
      class="copy-code d-flex justify-content-between align-items-center p-y-8 p-x-12 radius-24 cursor-pointer m-t-12 base-tooltip"
      :data-tooltip="isCopied ? 'Copied!' : 'Click to copy'"
      @click="copy"
    >
      <span class="fs-14 light-gray-color-text">{{ code }}</span>

      <img
        src="@/assets/images/profile/copy-code.svg"
        width="12"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'InviteYourFriend',
  data () {
    return {
      isCopied: false,
      code: 'hf7we7e33'
    }
  },

  methods: {
    async copy () {
      await navigator.clipboard.writeText(this.code)
      this.isCopied = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import './invite-your-friend';
</style>
